import request from '@/service/request'
//物流公司
export function list(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressCompany/list/',
    method: 'post',
    data
  })
}

//审核是否通过
export function checkWithdraw(data = {}) {
  return request({
    url: '/externaladmin/financeService/payment/withdrawDepositApprove',
    method: 'post',
    data
  })
}

//提现设置
export function setWithdraw(data = {}) {
  return request({
    url: '/externaladmin/systemService/systemConfig/updateWithdrawalSettings',
    method: 'post',
    data
  })
}

//获取提现设置数据
export function getWithDrawData(data = {}) {
  return request({
    url: '/externaladmin/systemService/systemConfig/getWithdrawalSettings',
    method: 'post',
    data
  })
}
