<template>
  <div>
    <div class="header-warpper">
      <div class="left-container">
        <el-form :inline="true" :model="query">
          <el-form-item label="申请用户：">
            <el-input
              @change="$emit(`search`)"
              size="mini"
              clearable
              style="width: 240px"
              placeholder="请输入用户名称"
              v-model="query.nickName"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户等级：" class="ml15">
            <el-select
              size="mini"
              @change="$emit(`search`)"
              style="width: 200px"
              v-model="query.userLevel"
              clearable
              placeholder="请选择用户等级"
            >
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间：" class="ml15">
            <el-date-picker
              v-model="query.createTime"
              type="daterange"
              size="mini"
              value-format="yyyy-MM-dd"
              placeholder="请选择创建时间"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 300px"
              @change="$emit(`search`)"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
              type="primary"
              @click="$emit(`search`)"
              icon="el-icon-search"
            ></el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-button
        v-p="['externaladmin:financialManagement:withdrawManagement:set']"
        @click="setWithdraw"
        style="height: 50px"
        type="text"
        icon="el-icon-s-tools"
        >提现设置</el-button
      >
    </div>
    <withdrawDialog
      :form.sync="form"
      :visible.sync="visible"
      @handleSuccess="handleSuccess"
      @refreshPage3="refreshPage2"
      @update="update"
    />
  </div>
</template>

<script>
import withdrawDialog from './withdrawDialog'
import { getVipList } from '@/api/vipLevel'
import { getWithDrawData } from '@/api/withdrawApi'

export default {
  components: { withdrawDialog },
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      settingData: {},
      visible: false,
      radio: 0,
      options: [],
      form: {
        minMoney: null,
        maxMoney: null,
        fee: 0,
        first: null,
        second: null,
        third: null,
        fourth: null
      }
    }
  },
  methods: {
    update(val) {
      this.form = {
        minMoney: null,
        maxMoney: null,
        fee: val,
        first: null,
        second: null,
        third: null,
        fourth: null
      }
    },
    setWithdraw() {
      //第一次请求没有数据
      if (this.settingData && Object.keys(this.settingData).length === 0) {
        // 两种方式都可以
        // this.form = { minMoney: null, maxMoney: null, fee: null }
        // this.form.fee = 0
        this.form = {}
        this.$set(this.form, 'fee', 1)
        this.visible = true
        return
      } else {
        //请求有数据时直接赋值
        const minMoney = this.settingData.find((item) => item.configCode == 'minimumAmount')?.configValue
        this.form.minMoney = minMoney
        const maxMoney = this.settingData.find((item) => item.configCode == 'dailyWithdrawLimit')?.configValue
        this.form.maxMoney = maxMoney
        const fee = this.settingData.find((item) => item.configCode == 'withdrawalType')?.configValue
        this.form.fee = Number(fee)
        const val = this.settingData.find((item) => item.configCode == 'withdrawalFee')?.configValue
        const percent = this.settingData.find((item) => item.configCode == 'withdrawalPercentage')?.configValue
        if (fee == 1) {
          this.form.first = val
        } else if (fee == 2) {
          this.form.second = percent
        } else {
          this.form.third = val
          this.form.fourth = percent
        }
        this.visible = true
      }
    },
    refreshPage2() {
      this.$emit('refreshPage1')
    },
    async handleSuccess() {
      try {
        const { code, detail } = await getWithDrawData()
        if (code == 0) {
          this.settingData = detail || {}
        }
      } catch (error) {}
    }
  },

  async created() {
    try {
      const page = {
        pageIndex: 1,
        pageSize: 0
      }
      const { code, detail } = await getVipList({ page })
      const { code: code1, detail: withDrawData } = await getWithDrawData()
      if (code == 0 && code1 == 0) {
        this.options = detail.map((item) => ({
          label: item.levelName,
          value: item.id
        }))
        this.settingData = withDrawData || {}
      }
    } catch (error) {}
  }
}
</script>

<style lang="scss" scoped>
.header-warpper {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .left-container {
    .ml15 {
      margin-left: 15px;
    }
  }
}
</style>
