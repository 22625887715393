<template>
  <el-dialog title="提现设置" :visible.sync="visible" width="580px" class="dialog-warpper" :before-close="handleClose">
    <el-form ref="form" :model="form" label-width="180px" :rules="rules">
      <el-form-item label="最低提现金额：" prop="minMoney">
        <el-input placeholder="请输入最低提现金额" style="width: 250px" size="small" v-model="form.minMoney"></el-input>
        <span class="ml10">元</span>
      </el-form-item>
      <el-form-item label="每日提现上限：" prop="maxMoney">
        <el-input placeholder="请输入每日提现上限" style="width: 250px" size="small" v-model="form.maxMoney"></el-input>
        <span class="ml10">元</span>
      </el-form-item>
      <el-form-item label="提现手续费：" prop="fee">
        <el-radio-group @change="onChange" v-model="form.fee">
          <el-radio :label="1">按次收费</el-radio>
          <el-radio :label="2">按比例收费</el-radio>
          <el-radio :label="3">混合收费</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.fee == 1" label="每次提现手续费用：" prop="first">
        <el-input
          placeholder="请输入每次提现手续费用"
          style="width: 250px"
          size="small"
          v-model="form.first"
        ></el-input>
        <span class="ml10">元</span>
      </el-form-item>
      <el-form-item v-else-if="form.fee == 2" label="提现手续费用百分比：" prop="second">
        <el-input
          placeholder="请输入提现手续费用百分比"
          style="width: 250px"
          size="small"
          v-model="form.second"
        ></el-input>
        <span class="ml10">%</span>
      </el-form-item>
      <div v-else>
        <el-form-item label="每次提现手续费用：" prop="third">
          <el-input
            placeholder="请输入每次提现手续费用"
            style="width: 250px"
            size="small"
            v-model="form.third"
          ></el-input>
          <span class="ml10">元</span>
        </el-form-item>
        <el-form-item label="提现手续费用百分比：" prop="fourth">
          <el-input
            placeholder="请输入提现手续费用百分比"
            style="width: 250px"
            size="small"
            v-model="form.fourth"
          ></el-input>
          <span class="ml10">%</span>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCanceled">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleConfirmed">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import { setWithdraw } from '@/api/withdrawApi'

export default {
  data() {
    var validateNumber = (rule, value, callback) => {
      if (value === '' || value < 0 || isNaN(value)) {
        callback(new Error('请输入正数'))
      }
      callback()
    }
    var moreThanzero = (rule, value, callback) => {
      if (Number(value) <= 0) {
        callback(new Error('不能为0'))
      }
      callback()
    }
    var validateMax = (rule, value, callback) => {
      if (Number(value) > 999999) {
        callback(new Error('最大不超过999999'))
      }
      callback()
    }
    var validateMaxMoney = (rule, value, callback) => {
      if (Number(value) <= Number(this.form.minMoney)) {
        callback(new Error('每日提现上限必须大于最低提现金额'))
      }
      callback()
    }
    var validateSecond = (rule, value, callback) => {
      if (value === '' || value >= 100 || value < 0 || isNaN(value)) {
        callback(new Error('提现手续费用百分比的值需介于0到100之间'))
      }
      callback()
    }

    var twoFixedInteger = (rule, value, callback) => {
      const reg = /^(([0-9]+)|([0-9]+\.[0-9]{0,2}))$/
      if (!reg.test(value)) {
        callback(new Error('小数不能超过两位'))
      }
      callback()
    }

    return {
      loading: false,
      rules: {
        minMoney: [
          { required: true, message: '最低提现金额的值不能为空', trigger: 'blur' },
          { validator: validateNumber, trigger: 'blur' },
          { validator: moreThanzero, trigger: 'blur' },
          { validator: validateMax, trigger: 'blur' },
          { validator: twoFixedInteger, trigger: 'blur' }
        ],
        maxMoney: [
          { required: true, message: '每日提现上限的值不能为空', trigger: 'blur' },
          { validator: validateNumber, trigger: 'blur' },
          { validator: validateMaxMoney, trigger: 'blur' },
          { validator: validateMax, trigger: 'blur' },
          { validator: twoFixedInteger, trigger: 'blur' }
        ],
        fee: [
          { required: true, message: '提现手续费不能为空', trigger: 'blur' },
          { validator: validateMax, trigger: 'blur' },
          { validator: twoFixedInteger, trigger: 'blur' }
        ],
        first: [
          { required: true, message: '每次提现手续费用的值不能为空', trigger: 'blur' },
          { validator: validateNumber, trigger: 'blur' },
          { validator: validateMax, trigger: 'blur' },
          { validator: twoFixedInteger, trigger: 'blur' }
        ],
        second: [
          { required: true, message: '提现手续费用百分比的值不能为空', trigger: 'blur' },
          { validator: validateSecond, trigger: 'blur' },
          { validator: twoFixedInteger, trigger: 'blur' }
        ],
        third: [
          { required: true, message: '每次提现手续费用的值不能为空', trigger: 'blur' },
          { validator: validateNumber, trigger: 'blur' },
          { validator: validateMax, trigger: 'blur' },
          { validator: twoFixedInteger, trigger: 'blur' }
        ],
        fourth: [
          { required: true, message: '提现手续费用百分比的值不能为空', trigger: 'blur' },
          { validator: validateSecond, trigger: 'blur' },
          { validator: twoFixedInteger, trigger: 'blur' }
        ]
      },
      formCopy: {}
    }
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (!val) return
        this.formCopy = deepClone(this.form)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
      // this.$refs.form.resetFields()
      this.$refs.form.clearValidate()
    },
    onSuccessMessage() {
      this.handleClose()
      this.$emit('refreshPage3')
      this.$message.success('保存成功')
    },
    getItem(code, value) {
      return {
        configType: 'withdrawalSettings',
        configCode: code,
        configName: code,
        configValue: value
      }
    },
    async handleConfirmed() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        if (!getDiffData(this.form, this.formCopy)) {
          this.onSuccessMessage()
          return
        }
        this.loading = true
        try {
          const list = [
            this.getItem('minimumAmount', this.form.minMoney),
            this.getItem('dailyWithdrawLimit', this.form.maxMoney),
            this.getItem('withdrawalType', this.form.fee)
          ]
          if (this.form.fee == 1) {
            list.push(this.getItem('withdrawalFee', this.form.first))
          } else if (this.form.fee == 2) {
            list.push(this.getItem('withdrawalPercentage', this.form.second))
          } else {
            list.push(
              this.getItem('withdrawalFee', this.form.third),
              this.getItem('withdrawalPercentage', this.form.fourth)
            )
          }
          const { code } = await setWithdraw({ list })
          if (code == 0) {
            this.loading = false
            this.onSuccessMessage()
            this.$emit('handleSuccess')
          }
        } catch (error) {
          this.loading = false
        }
      })
    },
    handleCanceled() {
      this.$emit('update:form', this.formCopy)
      console.log('wobei', this.formCopy)
      this.handleClose()
    },
    onChange(val) {
      this.$refs.form.clearValidate()
      this.$emit('update', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  .mr10 {
    margin-right: 10px;
  }
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>
